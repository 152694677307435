/* eslint-disable max-len */
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import CrownIcon from '../../assets/images/crown.svg'
import BannerImage from '../../assets/static/banner/banner_taichung.jpg'
import EnvironmentImg1 from '../../assets/static/clinic/taichung_environment1.jpg'
import EnvironmentImg2 from '../../assets/static/clinic/taichung_environment2.jpg'
import EnvironmentImg3 from '../../assets/static/clinic/taichung_environment3.jpg'
import EnvironmentImg4 from '../../assets/static/clinic/taichung_environment4.jpg'
import EnvironmentImg5 from '../../assets/static/clinic/taichung_environment5.jpg'
import EnvironmentImg6 from '../../assets/static/clinic/taichung_environment6.jpg'
import consultImage from '../../assets/static/consult/consult1.jpg'
import Button from '../../components/Button'
import { getClinicInfo } from '../../components/Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../components/Grid'
import ClinicLayout from '../../components/Layout/ClinicLayout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import { Primary, Taichung, TaichungSecondary } from '../../constants/newColor'
import { Body, H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { Location } from '../../types/types'
import CaseSection from './components/CaseSection'
import Consult from './components/Consult'
import DoctorSection from './components/Doctor'
import GoogleMap from './components/GoogleMap'
import LogoInfo from './components/LogoInfo'
import OtherClinic from './components/OtherClinic'
import TimetableSection from './components/Timetable'
import { taichungTimetableData } from './components/Timetable/staticData'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InfoTitleWrapper = styled.div`
  margin: 0 auto;
`

const ContentTitleWrapper = styled.div`
  border-bottom: 1px solid #aaaaaa;
  display: inline-block;
  padding-bottom: 4px;
  margin: ${space.l}px auto ${space.xl}px auto;
`

const ContentTitle = styled.h2`
  ${H2}
  position: relative;
  display: inline-block;
  border-top: 2px solid ${Taichung};
  border-bottom: 2px solid ${Taichung};
  color: ${Primary};
  padding: 0 ${space.m}px;

  &:before {
    content: '';
    display: block;
    width: 48px;
    height: 24px;
    background-image: url(${CrownIcon});
    background-size: cover;
    background-position: bottom center;

    position: absolute;
    top: -23px;
    right: 50%;
    transform: translate(50%, 0);
  }
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  @media (min-width: ${viewport.tablet}px) {
    height: 180px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 85px;
  }
`

const Info = styled.div`
  ${Body}
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MoreCase = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
`

const ConsultWrapper = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${viewport.desktop}px) {
    height: 406px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 294px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 200px;
  }
`

const ClinicTaichung = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    },
    {
      label: formatMessage({ id: 'clinic.taichung' }),
      path: '/clinic/taichung'
    }
  ]

  return (
    <ClinicLayout imageUrl={BannerImage} pageBannerInfo={formatMessage({ id: 'banner.taichung' })} routes={routes} {...props}>
      <Seo
        title='【台中牙醫推薦】日蒔美學牙醫診所：隱形矯正、矯正專科、陶瓷貼片'
        metaDescription='台中七期旁日蒔美學牙醫診所，位於文心路與大墩十四街口，捷運水安宮站出站2分鐘到達，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙等牙科治療，『穿越日蒔，重拾笑容的第一道曙光』是日蒔堅持的使命。'
      />
      <LogoInfo location={Location.Taichung} />
      <Content>
        <GridLayout templateColumns={['100%', '1fr 1fr']} templateAreas={[`'imgSection1' 'info' 'imgSection2'`, `'info info''imgSection1 imgSection2'`]} rowGap={['24px', '120px']}>
          <GridItem area='imgSection1'>
            <GridLayout templateColumns={['2fr 1fr 2fr']}>
              <Img src={EnvironmentImg1} />
              <Img src={EnvironmentImg2} />
              <Img src={EnvironmentImg3} />
            </GridLayout>
          </GridItem>
          <GridItem area='info'>
            <InfoTitleWrapper>
              <ContentTitleWrapper>
                <ContentTitle>關於我們</ContentTitle>
              </ContentTitleWrapper>
            </InfoTitleWrapper>
            <Info>
              <p>『穿越日蒔，重拾笑容的第一道曙光』</p>
              <p>
                日蒔牙醫診所位於台中南屯區文心路與大墩十四街口，捷運水安宮站出站2分鐘到達，鄰近屋馬燒肉文心店、家樂福
                文心店。日蒔牙醫聘請台大、北醫牙醫師駐診，其中包含數位矯正專科醫師。 承襲日本的服務精神與台灣的專業牙科治療，導入牙科數位化「3shape 口內掃描儀」，以及 MIT
                第一品牌「SOV 數位隱形矯正系統」，廣邀各專科權威，提供給您最專業、舒適的醫療環境。
              </p>
            </Info>
          </GridItem>
          <GridItem area='imgSection2'>
            <GridLayout templateColumns={['2fr 2fr 1fr']}>
              <Img src={EnvironmentImg4} />
              <Img src={EnvironmentImg5} />
              <Img src={EnvironmentImg6} />
            </GridLayout>
          </GridItem>
        </GridLayout>
      </Content>
      <Content>
        <ContentTitleWrapper>
          <ContentTitle>專業團隊</ContentTitle>
        </ContentTitleWrapper>
        <DoctorSection page={Location.Taichung} />
      </Content>
      <Content>
        <ContentTitleWrapper>
          <ContentTitle>矯正心得分享</ContentTitle>
        </ContentTitleWrapper>
        <CaseSection location={[Location.Taichung]} />
        <MoreCase href={`/case?location=${Location.Taichung}`}>
          <Button label='MORE &#9658;' type='secondary' />
        </MoreCase>
      </Content>
      <Content>
        <ConsultWrapper>
          <Consult color={Taichung} imageUrl={consultImage} />
        </ConsultWrapper>
      </Content>
      <Content>
        <ContentTitleWrapper>
          <ContentTitle>門診資訊</ContentTitle>
        </ContentTitleWrapper>
        <TimetableSection data={taichungTimetableData} primaryColor={Taichung} secondaryColor={TaichungSecondary} />
      </Content>
      <Content id='location'>
        <GoogleMap lat={24.15247} lng={120.64703} title='sunlight_taichung' add={getClinicInfo({ location: Location.Taichung, value: 'address' })} />
      </Content>
      <Content>
        <ContentTitleWrapper>
          <ContentTitle>其他診所</ContentTitle>
        </ContentTitleWrapper>
        <OtherClinic />
      </Content>
    </ClinicLayout>
  )
}

export default ClinicTaichung
